class Strings {
  GENERIC_ERROR = "An error occurred";
 
  notification = "notification";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";

  CONTACT = "+91-8439110001";
  SUPPORT = "pt.lalitlohani@gmail.com";
}

export default new Strings();
