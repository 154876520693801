class Urls {
  homePathView = "/";
  aboutUsPathView = "/about-us";
  contactUsViewPath = "/contact-us";
  privacyPolicyViewPath = "/privacy-policy";
  contactApiCallEndpoint = "http://192.168.10.60:5000/send_appointment";
  servicesViewPath = "/services";
  vedicKundaliServiceViewPath = "/services/vedic-kundali";
  vastuVisitServiceViewPath = "/services/vastu-visit";
  gemstoneServiceViewPath = "/services/gemstone";
  varshikFalServiceViewPath = "/services/varshik-fal";
  kundaliMilanServiceViewPath = "/services/kundali-milan";
  predictionsServiceViewPath = "/services/predictions";

  facebook = "https://www.facebook.com/lohanicommercecoaching?mibextid=ZbWKwL";
  instagram = "https://instagram.com/pt.lalit_lohani?igshid=OGQ5ZDc2ODk2ZA==";
}

export default new Urls();
